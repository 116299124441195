import React from 'react';

import Layout from "../components/layout.js";
import Back from "../components/back.js";
import Hero from "../components/hero.js";

import { FAQJsonLd } from 'gatsby-plugin-next-seo';


// import AppleAppStoreBageImg from "../images/apple-app-store-badge-outline.svg";

const IndexPage = () => {

  return (
    <Layout>
    	<FAQJsonLd
	      questions={[
	        { question: 'What?', answer: 'Stand' },
	        { question: 'How?', answer: 'Effort' },
	        { question: 'Why?', answer: 'Peace' },
	      ]}
	    />

    	<Back/>
    	<h1><Hero>FAQ</Hero></h1>
    	<h2>Was ist Ambia?</h2>
        <p>Ambia ist eine App, mit der du den Outdoor-Trail um das Chocolarium erleben kannst. Die App gibt dir anhand deines Standorts an, ob du am richtigen Ort bist, um das Game zu starten. Der Outdoor-Trail ist eine unterhaltende Reise durch den Naturpark Botsberger Riet auf der du die spannenden Figuren von Maestrani-Schokolade triffst und Puzzles in Augmented Reality (AR) löst.</p>
        <h2>Was ist das Chocolarium?</h2>
        <p>Das Chocolarium ist eine Erlebniswelt rund um Schokolade der auf ganzen Welt bekannten Brands Maestrani, Minor und Munz.</p>
        <h2>Wo ist das Chocolarium?</h2>
        <p>Maestranis Chocolarium befindet sich in an der Toggenburgerstrasse 41 in 9230 Flawil, Schweiz. Es ist eine Erlebniswelt der Schokolade und eine interaktive Fabrikbesichtigung.</p>
        <h2>Wie funktioniert das Game?</h2>
        <p>Das Spiel Chocolarium ist eine Mixed-Reality-Abenteuerreise, bei denen du durch das Botsberger Riet spazierst und verschiedenen Figuren aus der Schokoladenwelt von Maestrani begegnest und ihnen hilfst, Dinge in visuellen 3D-Rätseln zu finden. Als erstes gehst du an den Eingang des Maestrani Chocolariums. Danach kannst du dem Pfad folgen und die verschiedenen 3D-Rätsel absolvieren.</p>
        <h2>Wie lange dauert das Spiel?</h2>
        <p>Ein Rundgang dauert zwischen 30 und 90 Minuten und kann jederzeit abgebrochen werden.</p>
        <h2>Wie weit muss ich laufen?</h2>
        <p>Die Tour durch das Botsberger Riet ist 2.12 Kilometer lang (1.32 mi).</p>
        <h2>Kann ich das Game auch mit meiner Familie gemeinsam nutzen?</h2>
        <p>Ja. Die App funktioniert auf dem Handy (Android und iPhone) sowie auf Tablets. Auf dem Spaziergang durch das Botsberger Riet kannst du und deine ganze Familie und all deine Freunde gemeinsam an einem oder mehreren deiner von Zuhause mitgebrachten Geräten spielen. Die App ist so eingerichtet, dass Du die verschiedenen Rätsel mehrmals durchspielen kannst.</p>
        <h2>Ist die Tour rollstuhlgängig?</h2>
        <p>Ja, die Tour ist im Freien und führt über geteerte Strassen und Feldwege und als solches ohne Barrieren und Treppentritte zugänglich. Der Abschnitt «Oberer Botsberg» ist ein Feldweg mit groben Kieselsteinen und etwas steilerer Steigung.</p>
        <h2>Kann ich den Kinderwagen mit auf die Tour nehmen?</h2>
        <p>Ja, die Tour ist im Freien und führt über geteerte Strassen und Feldwege und als solches ohne Barrieren und Treppentritte zugänglich. Der Abschnitt «Oberer Botsberg» ist ein Feldweg mit groben Kieselsteinen und etwas steilerer Steigung.</p>
        <h2>Kann ich das Game auch ausserhalb von Flawil benutzen?</h2>
        <p>Die Tour des Maestrani Chocolariums ist zur Zeit nur in Flawil selbst erlebbar.</p>
        <h2>Auf welchen Geräten funktioniert die Game-App?</h2>
        <p>Die App «Ambia» für die Tour des Maestrani Chocolariums ist für Android-Telefone im Google Play Store und für iPhones auf dem Apple App Store verfügbar. Die App kann auf Mobiltelefonen und Tablets verwendet werden.</p>
        <p>Zur Zeit ist die App in den Ländern Schweiz, Liechtenstein, Deutschland, Österreich, Italien und Frankreich verfügbar.</p>
        <h2>Auf welche Sprachen ist die Game-App verfügbar?</h2>
        <p>Die Tour des Maestrani Chocolariums ist auf Deutsch und Englisch verfügbar.</p>
        <h2>Warum braucht die Game-App Zugriff auf meinen Standort?</h2>
        <p>Die App Ambia führt die Teilnehmenden der Tour des Maestrani Chocolariums mittels dem GPS-Signal durch das Botsberger Riet. Um dies zu ermöglichen, braucht die App Zugriff auf den Standort. Dieses Zugriffsrecht kann über das Menü Privatsphäre in den Systemeinstellungen jederzeit wieder entfernt werden. Siehe auch unsere Ausführungen zum Datenschutz.</p>
        <h2>Warum braucht die Game-App Zugriff auf meine Kamera?</h2>
        <p>Die App Ambia zeigt den Teilnehmenden der Tour des Maestrani Chocolariums 3D-Puzzles auf dem Kamerabild. Damit dies technisch möglich wird, braucht die App Zugriff auf die Kamera. Die App speichert diese Bilddaten zu keiner Zeit. Dieses Zugriffsrecht kann über das Menü Privatsphäre in den Systemeinstellungen jederzeit wieder entfernt werden. Siehe auch unsere Ausführungen zum Datenschutz.</p>
        <h2>Was bedeutet Augmented Reality (AR)?</h2>
        <p>Wenn digitale Welten und Figuren über ein Kamerabild gelegt werden, bezeichnet man das als Augmented Reality (AR) oder Mixed-Reality (MR). Die Tour des Maestrani Chocolariums setzt Augmented Reality ein, indem digitale 3D-Puzzles an verschiedenen physischen Orten rund um die Erlebniswelt Chocolarium in Flawil, Schweiz, in der Kamera platziert werden. Für diese Augmented Reality-Erfahrung ist eine App erforderlich.</p>
        <h2>Wie viel kostet die Game-App?</h2>
        <p>Die App Ambia und die darin enthaltene Tour bei Maestrani’s Chocolarium ist kostenlos verfügbar für Android- und iOS-Geräte, Mobiltelefon wie auch Tablets.</p>
        <h2>Wer steckt hinter der Game-App?</h2>
        <p>Die Game-App Ambia wird durch das Schweizer Mixed-Reality-Game-Studio Gbanga, Millform AG entwickelt. Die Abenteuer-Tour für Maestrani’s Chocolarium wurde in Zusammenarbeit mit Maestrani umgesetzt und ist die offizielle digitale Tour der Erlebniswelt Chocolarium.</p>
        <h2>Ich möchte meine Daten löschen.</h2>
        <p>Ihre allenfalls gespeicherten persönlichen Daten können Sie durch eine E-Mail an info&#64;ambia.app unter Angabe von Vorname, Nachname sowie E-Mail-Adresse löschen lassen. Siehe auch unsere Ausführungen zum Datenschutz.</p>

    </Layout>
    )
}

export default IndexPage
